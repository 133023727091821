<template>
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper-moble" @click.stop>
      <div class="change-box">
        <div class="wrapper-moble-img">
          <img src="../assets/images/yhjuan.png" />
        </div>
        <div class="wrapper-moble-console" @click="consoleClick">
          <img src="../assets/images/console.png" />
        </div>
        <h2 class="change-title">您有一张卡券待领取</h2>
        <div class="change-title-text">
          登录后即可使用卡券，另有海量商品等你发现
        </div>
        <div class="input-box">
          <input
            class="mobile-text"
            v-model="tel"
            maxlength="11"
            placeholder="请输入手机号"
            type="text"
            @blur="inputBlur"
          />
          <div class="code-box">
            <input
              class="code-text"
              v-model="code"
              maxlength="6"
              placeholder="请输入短信验证码"
              type="text"
              @blur="inputBlur"
            />
            <span class="code-btn" @click="getCode" :disabled="isVertify">{{
              codeText
            }}</span>
          </div>
        </div>
        <div class="action-box">
          <van-button class="action ok-btn" type="primary" @click="login">
            登录
          </van-button>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Overlay, Button } from "vant";
import { getLoginMsg, login } from "@/api/common";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "CardMobile",
  props: {
    value: {
      default: false,
    },
    step: {
      default: 1,
    },
    cardNumber: {
      require: true,
    },
  },
  components: {
    [Overlay.name]: Overlay,
    [Button.name]: Button,
  },
  computed: {
    ...mapState(["telReg"]),
    show: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("input", false);
      },
    },
  },
  data() {
    return {
      tel: "",
      code: "",
      codeText: "获取验证码",
      num: 60,
      timer: null,
      isVertify: false,
      submitDisabled: false,
    };
  },
  watch: {
    tel: function (val) {
      this.tel = val.replace(/\D/g, "");
    },
  },
  methods: {
    ...mapMutations(["setLoginStatus"]),
    ...mapActions(["getUserInfo", "dispatchGetCardCount"]),
    inputBlur() {
      document.body.scrollIntoView(); // 回顶部
    },
    cancel() {
      this.$emit("onCancel");
    },
    consoleClick() {
      this.$emit("onCancel");
    },

    // 获取验证码
    async getCode() {
      if (!this.tel) {
        this.$toast("请输入手机号！");
        return;
      }
      if (!this.telReg.test(this.tel)) {
        this.$toast("手机号格式错误！");
        return;
      }
      if (this.isVertify) return;
      this.isVertify = true;
      try {
        const res = await getLoginMsg({
          mobile: this.tel,
        });
        if (res && res.code == 0) {
          this.$toast.success("发送成功！");
          this.timer = setInterval(() => {
            if (this.num <= 0) {
              clearInterval(this.timer);
              this.num = 60;
              this.codeText = "获取验证码";
              this.isVertify = false;
            } else {
              this.num = this.num - 1;
              this.codeText = `${this.num}s`;
              this.isVertify = true;
            }
          }, 1000);
        } else {
          this.$toast(res.message);
          this.isVertify = false;
        }
      } catch (error) {
        this.isVertify = false;
      }
    },

    // 点击登录
    async login() {
      if (!this.tel || !this.code) {
        return;
      }
      if (!this.telReg.test(this.tel)) {
        this.$toast("手机号格式错误！");
        return;
      }
      const params = {
        mobile: this.tel,
        code: this.code,
      };
      this.$loading("登录中...");
      try {
        const res = await login(params);
        this.$toast.clear();
        console.log(res);
        if (res && res.code == 0) {
          console.log(res.code);
          localStorage.setItem("login_time", new Date().getTime());
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          this.getUserInfo();
          this.dispatchGetCardCount();
          this.$emit("onOk", this.cardNumber);
          this.setLoginStatus({ status: true });
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper-moble {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .change-box {
    width: 5.9rem;
    height: 7rem;
    background: #ffffff;
    border-radius: 0.2rem;
    padding: 0 0.4rem;
    position: relative;

    .wrapper-moble-img {
      top: -1.2rem;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 3.2rem;
      height: 2.4rem;
      > img {
        width: 100%;
        height: 100%;
      }
    }

    .wrapper-moble-console {
      top: -1.8rem;
      right: 0rem;
      position: absolute;
      width: 0.39rem;
      height: 0.39rem;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    input {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      padding: 0 0.2rem;
      box-sizing: border-box;
      background: #fff;
    }
    input[type="text"] {
      -webkit-appearance: none;
    }

    .change-title {
      font-size: 0.36rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      margin-top: 1.6rem;
      text-align: center;
      margin-bottom: 0.16rem;
    }

    .change-title-text {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
    }

    .input-box {
      display: flex;
      flex-direction: column;
      margin-top: 0.52rem;

      .mobile-text {
        height: 0.8rem;
        background: #ffffff;
        border-radius: 0.08rem;
        border: 0.02rem solid #eaf0f0;
        outline: none;
        margin-top: 0.08rem;
        background-color: #f4f6f8;
      }
      .code-title {
        margin-top: 0.28rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .code-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.3rem;
        position: relative;
        .code-text {
          width: 5.2rem;
          height: 0.8rem;
          background: #ffffff;
          border-radius: 0.08rem;
          border: 0.02rem solid #eaf0f0;
          outline: none;
          background-color: #f4f6f8;
        }

        .code-btn {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff4800;
          position: absolute;
          right: 0.2rem;
        }
      }
    }
    .action-box {
      margin-top: 0.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 0.9rem;
      overflow: hidden;

      /deep/ .van-button--primary {
        border: 0;
      }

      .action {
        width: 5.98rem;
        height: 0.8rem;
        background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
        border-radius: 0.08rem;

        &.ok-btn {
          background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
          border-color: transparent;
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fefffe;
        }
      }
    }
  }
}
</style>