<template>
  <van-list
    v-model="loading"
    :finished="finished"
    :loading-text="loadingText"
    finished-text="我也是有底线的~"
    @load="getDataList"
  >
    <div class="product-rights">
      <div class="rights-item" v-for="item in goodsList" :key="item.id">
        <div class="rights-left"></div>
        <div class="rights-right">
          <div class="goods-name one-line-hidden">{{ item.name }}</div>
          <div class="goods-remark one-line-hidden">{{ item.remark }}</div>
          <div class="goods-action">
            <span @click="clickGetTicket(item)" class="btn">去领券</span>
          </div>
        </div>
      </div>
    </div>
  </van-list>
</template>

<script>
import { List } from "vant";
export default {
  name: "RightsGoods",
  components: {
    [List.name]: List,
  },
  data() {
    return {
      loading: false,
      finished: true,
      loadingText: "",
      goodsList: [
        {
          id: 1,
          name: "美团外卖30元红包券",
          remark: "美团外卖最高30元红包券",
        },
        {
          id: 2,
          name: "优酷PC移动影视会员月卡限时特惠账号直发送到发送到",
          remark:
            "美团外卖最高30元红包券优酷PC移动影视会员月卡限时特惠账号直发送到发送到",
        },
        {
          id: 3,
          name: "优酷PC移动影视会员月卡限时特惠账号直发送到发送到",
          remark:
            "美团外卖最高30元优酷PC移动影视会员月卡限时特惠账号直发送到发送到红包券",
        },
        {
          id: 4,
          name: "优酷PC移动影视会员月卡限时特惠账号直发送到发送到",
          remark: "美团外卖最高30元红包券",
        },
        {
          id: 5,
          name: "优酷PC移动影视会员月卡限时特惠账号直发送到发送到",
          remark: "美团外卖最高30元红包券",
        },
        {
          id: 6,
          name: "优酷PC移动影视会员月卡限时特惠账号直发送到发送到",
          remark: "美团外卖最高30元红包券",
        },
      ],
    };
  },
  methods: {
    // 获取商品列表
    getDataList() {},

    // 点击去领券
    clickGetTicket(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="less" scoped>
.product-rights {
  .rights-item {
    height: 1.92rem;
    background: #ffffff;
    border-radius: 0.16rem;
    margin-bottom: 0.16rem;
    display: flex;
    padding: 0.16rem;
    box-sizing: border-box;

    .rights-left {
      width: 2.7rem;
      height: 1.6rem;
      border-radius: 0.06rem;
      overflow: hidden;
      background-color: cornflowerblue;
    }

    .rights-right {
      flex: 1;
      box-sizing: border-box;
      padding: 0 0.24rem;
      overflow: hidden;

      .goods-name {
        font-size: 0.28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      .goods-remark {
        margin-top: 0.08rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }

      .goods-action {
        margin-top: 0.28rem;
        height: 0.4rem;
        display: flex;
        justify-content: flex-end;

        .btn {
          width: 1.08rem;
          height: 0.4rem;
          border-radius: 0.24rem;
          border: 0.02rem solid #ff503f;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.24rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff503f;
        }
      }
    }
  }
}
</style>