<template>
  <div class="search-bar" @click="toSearch">
    <div class="left-box">
      <img class="search-icon" src="../assets/images/search.png" alt="" />
      <span class="name-tip">腾讯vip会员月卡</span>
    </div>
    <span class="search-btn">搜索</span>
  </div>
</template>

<script>
import { Icon } from "vant";
export default {
  name: "SearchBar",
  components: {
    [Icon.name]: Icon,
  },
  methods: {
    toSearch() {
      this.$router.push("/mall/SearchGood");
    },
  },
};
</script>

<style lang="less" scoped>
.search-bar {
  width: 100%;
  height: 0.64rem;
  background: #ededed;
  border-radius: 0.36rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.04rem 0 0.32rem;
  box-sizing: border-box;

  .left-box {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .search-icon {
    width: 0.26rem;
    height: 0.28rem;
    margin-right: 0.22rem;
  }

  .name-tip {
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a7a7a7;
  }

  .search-btn {
    width: 1.22rem;
    height: 0.52rem;
    background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
    border-radius: 0.32rem;
    font-size: 0.26rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #f6f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
