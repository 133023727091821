<template>
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper-moble-login" @click.stop>
      <div class="change-box">
        <div class="wrapper-moble-img">
          <img src="../assets/images/yhjuan.png" />
        </div>
        <div class="wrapper-moble-console" @click="cancel">
          <img src="../assets/images/console.png" />
        </div>
        <h2 class="change-title">卡券领取成功</h2>
        <div class="change-title-text">可前往“我的卡券”中查看</div>
        <div class="action-box">
          <van-button class="action ok-btn" type="primary" @click="onOk">
            立即使用
          </van-button>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Overlay, Button } from "vant";
import { mapActions } from "vuex";
export default {
  name: "CardMobileView",
  props: {
    value: {
      default: false,
    },
    couponData: {
      default: () => {},
    },
    productType: {
      require: true,
    },
  },
  components: {
    [Overlay.name]: Overlay,
    [Button.name]: Button,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("input", false);
      },
    },
  },

  data() {
    return {};
  },
  methods: {
    ...mapActions(["dispatchGetCardCount"]),
    cancel() {
      this.$emit("onCancel");
    },
    onOk() {
      const { productId } = this.couponData;
      if (this.productType == 30 || this.productType == 40) {
        this.$router.push(
          `/mall/phoneBill?productType=${this.productType}&productId=${productId}`
        );
      } else {
        this.$router.push(`/mall/ProductDetails?productId=${productId}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper-moble-login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .change-box {
    width: 5.9rem;
    height: 4.8rem;
    background: #ffffff;
    border-radius: 0.2rem;
    padding: 0 0.4rem;
    position: relative;

    .wrapper-moble-img {
      top: -1.2rem;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 3.2rem;
      height: 2.4rem;
      > img {
        width: 100%;
        height: 100%;
      }
    }

    .wrapper-moble-console {
      top: -1.8rem;
      right: 0;
      position: absolute;
      width: 0.39rem;
      height: 0.39rem;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .change-title {
      font-size: 0.36rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      margin-top: 1.6rem;
      text-align: center;
      margin-bottom: 0.16rem;
    }

    .change-title-text {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff4800;
      text-align: center;
    }

    .action-box {
      margin-top: 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .action {
        width: 5.98rem;
        height: 0.8rem;
        background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
        border-radius: 0.08rem;

        &.ok-btn {
          background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
          border-color: transparent;
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fefffe;
        }
      }
    }
  }
}
</style>