<template>
  <div class="home-page">
    <div class="bgc-box"></div>

    <!-- <van-sticky>
      <div class="home-search-box">
        <SearchBar />
      </div>
    </van-sticky> -->
    <div class="search-div">
      <div class="home-search-box">
        <SearchBar />
      </div>
    </div>
    <!-- <div class="search-div-content"></div> -->
    <!-- <div class="hot-tag-box">
      <div class="tag-box">
        <div
          class="tag-item"
          @click="clickProduct(item.id)"
          v-for="item in hotTagList"
          :key="item.id"
        >
          {{ item.productName }}
        </div>
      </div>
    </div>-->
    <div class="main-container">
      <div class="banner-box">
        <van-swipe :autoplay="5000" indicator-color="#fff">
          <van-swipe-item
            v-for="item in swiperList"
            :key="item.id"
            @click="clickBanner(item)"
          >
            <img
              class="swiper-img"
              v-lazy="item.bannerImg || defaultProductImg"
            />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="promise-box">
        <icon-svg iconClass="promise" class="promise-svg"></icon-svg>
      </div>
      <div class="menu-box">
        <van-swipe indicator-color="red">
          <van-swipe-item v-for="(item, i) in menuList" :key="i">
            <van-grid class="menu-grid" :column-num="5" :border="false">
              <van-grid-item
                v-for="el in item"
                :key="el.id"
                icon="cart-circle-o"
                :text="el.cateName.substr(0, 5)"
                @click="clickMenu(el)"
              >
                <template slot="icon">
                  <!-- <icon-svg :iconClass="item.icon" class="tab-svg"></icon-svg> -->
                  <div class="icon-box">
                    <img class="icon" :src="el.cateImg" alt />
                  </div>
                  <img
                    v-if="el.isHot"
                    class="tag-img"
                    src="../assets/images/hot.gif"
                    alt
                  />
                </template>
              </van-grid-item>
            </van-grid>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="notice-box" v-if="isLogin && cardCount">
        <div class="notice-left">
          <span class="tag-name">卡券</span>
          <span class="notice-text">
            您有
            <i>{{ cardCount }}</i> 张卡券待使用
          </span>
        </div>
        <div class="notice-right">
          <span class="right-btn-use" @click="toCoupon">去使用</span>
        </div>
      </div>
      <div class="hot-conver-box">
        <div class="conver-title">
          <div class="titles">
            <div class="title-left">
              <img class="rq-icon" src="../assets/images/super_hot.png" alt />
              <span class="main-title">
                <img
                  src="../assets/images/fire.png"
                  alt="fire"
                  style="margin-right: 0.06rem"
                />
                超值商品 精选推荐</span
              >
            </div>
            <div class="title-right">
              <div class="top-goods">
                <div>TOP 10</div>
                <div>精选好货</div>
              </div>
              <div class="top-banner">
                <div>大牌好货</div>
                <div>抢先看</div>
              </div>
            </div>
          </div>
        </div>
        <div class="conver-list">
          <div class="item-box">
            <div
              class="convert-item"
              v-for="item in hotProductList"
              :key="item.id"
              @click="clickProduct(item.id)"
            >
              <div class="conver-content">
                <div class="img-box">
                  <img :src="item.productImg || defaultProductImg" alt="" />
                </div>
                <div class="content-box">
                  <div class="product-name one-line-hidden">
                    <!-- <span class="icon">人气</span> -->
                    {{ item.productName }}
                  </div>
                  <icon-svg iconClass="super_hot_svg" class="super-hot-svg" />
                  <div class="integral-box">
                    <div class="rq-price">
                      <div class="price-left">
                        <div class="price-top">
                          <span class="now-price">
                            <span class="rmb"
                              >¥
                              {{
                                mathManage.formatMoney(
                                  item.productMinSalePrice,
                                  2
                                )
                              }}</span
                            >
                            <span
                              style="font-size: 0.2rem; margin-left: 0.04rem"
                              >起</span
                            >
                          </span>
                          <!-- <span class="before-coupon">
                            <span class="rmb">￥</span
                            >{{
                              mathManage.formatMoney(item.productMaxFace, 2)
                            }}</span
                          > -->
                        </div>
                        <div
                          class="price-bottom"
                          v-if="item.productMaxFace > item.productMaxSalePrice"
                        >
                          <icon-svg
                            iconClass="down_price"
                            class="down-price"
                          ></icon-svg>

                          直降{{
                            mathManage.formatMoney(
                              item.productMaxFace - item.productMaxSalePrice,
                              2
                            )
                          }}元
                        </div>
                      </div>
                      <div class="price-right">
                        <span>抢购</span><van-icon name="arrow" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed-product">
        <div class="fixed-top">
          <div class="fixed-left coupon-center" @click="toCouponCenter">
            <h3>领券中心</h3>
            <h6>限时闪购 超值折扣</h6>
            <div class="action-btn">
              领券<van-icon class="arrow" name="arrow" />
            </div>
            <div class="tag" v-if="!couponInfo">
              <span>省钱券包</span>
            </div>
            <div v-if="couponInfo">
              <img class="coupon-img" :src="couponInfo.promotionImg" alt />
              <div class="coupon-name one-line-hidden">
                {{ couponInfo.couponName }}
              </div>
              <div class="price-box">
                <div class="price-left">
                  <span class="unit">￥</span>
                  <span class="new-price">{{
                    couponInfo.couponAfterPrice
                  }}</span>
                  <span class="old-price"
                    >￥{{ couponInfo.productSalePrice }}</span
                  >
                </div>
                <div class="price-right">立减{{ couponInfo.amount }}元</div>
              </div>
            </div>
            <div v-else class="no-center">
              <img
                class="no-coupon"
                src="../assets/images/home-coupon-center.png"
                alt=""
              />
            </div>
          </div>

          <div class="fixed-left coupon-center bill" @click="toPhoneBill">
            <h3>话费充值</h3>
            <h6>官方渠道 安全放心</h6>
            <div class="action-btn">
              充值<van-icon class="arrow" name="arrow" />
            </div>
            <div class="tag">
              <span>极速到账</span>
            </div>
            <div class="no-center">
              <img
                class="no-coupon"
                src="../assets/images/home-bill-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="fixed-right">
          <div class="product product-one" @click="toAllProduct(1)">
            <img src="../assets/images/goods2.png" alt />
          </div>
          <div class="product product-two" @click="toAllProduct(2)">
            <img src="../assets/images/goods3.png" alt />
          </div>
        </div>
      </div>
      <div class="product-box">
        <!-- <div class="product-tab">
        <div
          :class="tab == 1 ? 'nav-box active' : 'nav-box'"
          @click="changeTab(1)"
        >
          <span class="main-title">数字商品</span>
          <span class="sub-title">猜你喜欢</span>
        </div>
        <div class="spancee-line"></div>
        <div
          :class="tab == 2 ? 'nav-box active' : 'nav-box'"
          @click="changeTab(2)"
        >
          <span class="main-title">权益商品</span>
          <span class="sub-title">越用越省</span>
        </div>
        </div>-->
        <!-- <div class="you-like-box">
        <div class="like-icon">
          <img src="../assets/images/like.png" alt="" />
        </div>
        <span class="like-title">猜你喜欢</span>
        </div>-->
        <div class="product-list">
          <!-- <DigitalGoods v-if="tab == 1" /> -->
          <!-- <RightsGoods v-else /> -->
          <van-list
            v-model="loading"
            :finished="finished"
            :loading-text="loadingText"
            :finished-text="finishedText"
            @load="getDataList"
          >
            <product-list :data="goodsList" />
            <!-- <water-list :data="goodsList" /> -->
          </van-list>
        </div>
      </div>
    </div>
    <Card-mobile
      v-model="showMobile"
      :cardNumber="code"
      :couponData="couponData"
      :productType="productType"
      @onCancel="showMobile = false"
      @onOk="changeMobileConfirm"
    />
    <Card-mobile-View
      v-model="showViewMobile"
      :cardNumber="code"
      :couponData="couponData"
      :productType="productType"
      @onCancel="showViewMobile = false"
      @onOk="changeMobileConfirm"
    />
    <van-button
      type="default"
      class="backTop"
      @click="backTop"
      v-show="flag_scroll"
    >
      <van-icon name="arrow-up" size="20" />
    </van-button>
  </div>
</template>

<script>
import SearchBar from "../components/SearchBar.vue";
import DigitalGoods from "../components/DigitalGoods.vue";
import RightsGoods from "../components/RightsGoods.vue";
import {
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Icon,
  List,
  Sticky,
  Button,
} from "vant";
import CardMobile from "../components/CardMobile.vue";
import CardMobileView from "../components/CardMobileView.vue";
import {
  getBannerList,
  getCateList,
  digitalproductpage,
  ReceiveCoupon,
  refreshToken,
  getHomeCouponInfo,
} from "@/api/common";
import ProductList from "@/components/ProductList.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import mathManage from "@/utils/mathManage";
import IconSvg from "@/components/IconSvg.vue";
import "../assets/images/svg/promise.svg";
import "../assets/images/svg/rq.svg";
import "../assets/images/svg/super_hot_svg.svg";
import "../assets/images/svg/down_price.svg";
import WaterList from "@/components/WaterList.vue";
export default {
  name: "Home",
  components: {
    SearchBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [List.name]: List,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Icon.name]: Icon,
    [Sticky.name]: Sticky,
    [Button.name]: Button,
    DigitalGoods,
    RightsGoods,
    CardMobile,
    CardMobileView,
    ProductList,
    IconSvg,
    WaterList,
  },
  data() {
    return {
      mathManage,
      swiperList: [],
      menuList: [],
      tab: 1,
      showMobile: false,
      showViewMobile: false,
      loading: false,
      finished: false,
      loadingText: "",
      finishedText: "我也是有底线的~",
      goodsList: [],
      hotProductList: [],
      PageIndex: 1,
      PageSize: 10,
      code: "", // 优惠券code
      couponData: {},
      productType: "",
      fromTicket: false,
      flag_scroll: false,
      couponInfo: null, // 首页优惠券展示商品
    };
  },
  computed: {
    ...mapState([
      "isLogin",
      "isInWx",
      "cardCount",
      "defaultProductImg",
      "mallInfo",
    ]),
    hotTagList() {
      return this.hotProductList.slice(0, 4).map((item) => {
        return {
          ...item,
          productName:
            item.productName.length > 5
              ? `${item.productName.substr(0, 5)}...`
              : item.productName,
        };
      });
    },
  },
  watch: {
    mallInfo: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal && newVal.mallName != oldVal.mallName) {
          document.title = newVal.mallName;
        }
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例
      if (from.path == "/ticket" || from.path == "/common") {
        vm.fromTicket = true;
      }
    });
  },
  created() {
    const code = this.$route.query.ticketCode;
    this.code = code;
    this.couponData = {
      productId: this.$route.query.productId,
    };
    this.productType = this.$route.query.productType;
    this.$nextTick(() => {
      if (code && this.isLogin) {
        this.receiveCoupon(this.code, true);
      }
      if (code && !this.isLogin && this.fromTicket) {
        setTimeout(() => {
          this.showMobile = true;
        }, 500);
      }
    });
  },
  mounted() {
    document.title = this.mallInfo.mallName;
    this.getBannerList();
    this.getCateList();
    this.getHotProduct();
    this.getHomeCouponInfo();
    document.addEventListener("scroll", this.handleScroll, true);
    // start
    // end
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    ...mapActions(["dispatchGetCardCount"]),
    ...mapMutations(["setProductName"]),
    // 获取轮播图
    async getBannerList() {
      const res = await getBannerList({
        BannerStatus: 10,
      });
      if (res && res.code == 0) {
        this.swiperList = res.data?.list || [];
      } else {
        this.$toast(res.message);
      }
    },

    // 获取分类菜单
    async getCateList() {
      const res = await getCateList({
        PageIndex: 1,
        PageSize: 999,
      });
      if (res && res.code == 0) {
        this.menuList = this._.chunk(
          (res.data?.list || []).map((item, index) => {
            let isHot = false;

            if (
              (item.tagList || []).find(
                (el) => el.tagName.toUpperCase() == "HOT"
              )
            ) {
              isHot = true;
            }
            return {
              ...item,
              isHot,
            };
          }),
          10
        );
      } else {
        this.$toast(res.message);
      }
    },

    // 获取首页商品列表
    async getDataList() {
      try {
        const res = await digitalproductpage({
          ProductTag: configs.tagProduct[3], // 首页商品
          PageIndex: this.PageIndex,
          PageSize: this.PageSize,
          ProductStatus: 10,
        });
        if (res && res.code == 0) {
          if (res.data.list.length > 0) {
            this.PageIndex = this.PageIndex + 1;
          }
          this.goodsList = [...this.goodsList, ...(res.data.list || [])];
          this.loading = false;
          this.$nextTick(() => {
            if (this.goodsList.length >= res.data.total) {
              this.finished = true;
            }
          });
        } else {
          this.$toast(res.message);
          this.loadingText = res.message;
          this.loading = false;
          this.finished = true;
        }
      } catch (error) {
        this.loading = false;
        this.finished = true;
      }
    },

    // 获取人气兑换商品
    async getHotProduct() {
      const res = await digitalproductpage({
        ProductTag: configs.tagProduct[1], // 人气兑换商品
        PageIndex: 1,
        PageSize: 10,
        ProductStatus: 10,
      });
      if (res && res.code == 0) {
        this.hotProductList = res.data?.list || [];
      } else {
        this.$toast(res.message);
      }
    },

    // 领取卡券
    async receiveCoupon(batchId) {
      this.batchId = batchId;
      if (!batchId || !this.fromTicket) return;
      try {
        const res = await ReceiveCoupon({
          batchId: batchId,
          from: 1,
        });
        if (res && res.code == 0) {
          this.dispatchGetCardCount();
          // this.$toast.success("领取成功！");
          this.showMobile = false;
          setTimeout(() => {
            this.showViewMobile = true;
          }, 500);
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        setTimeout(() => {
          if (!this.isLogin && !this.isInWx) {
            this.showMobile = true;
          }
        }, 1000);
      }
    },

    // 点击banner
    clickBanner(data) {
      const { productId } = data;
      if (!productId) return;
      this.$router.push(`/mall/ProductDetails?productId=${productId}`);
    },

    // 点击商品
    clickProduct(groupId) {
      if (!groupId) return;
      this.$router.push(`/mall/ProductDetails?groupId=${groupId}`);
    },

    // 点击菜单
    clickMenu(item) {
      if (item.cateType == 20) {
        this.$router.push("/mall/phoneBill");
        return;
      }
      this.$router.push({
        path: "/mall/goods",
        query: {
          id: item.id,
        },
      });
    },

    // 点击更多
    clickMore() {
      this.$router.push("/mall/goods");
    },

    // 切换 数字权益/权益商品
    changeTab(tab) {
      this.tab = tab;
    },

    changeMobileConfirm(batchId) {
      this.showMobile = false;
      this.showViewMobile = false;
      if (batchId) {
        this.receiveCoupon(batchId);
      }
    },

    toCoupon() {
      this.$router.push("/mall/ticket");
    },

    toPhoneBill() {
      this.$router.push("/mall/phoneBill");
    },
    toCouponCenter() {
      this.$router.push("/mall/CouponCenter");
    },

    toAllProduct(type) {
      let name = "";
      if (type == 1) {
        name = configs.searchName1;
      } else {
        name = configs.searchName2;
      }
      this.setProductName(name);
      this.$router.push(`/mall/goods`);
    },

    // 一键回到顶部按钮的显示隐藏
    handleScroll(env) {
      let scrollTop = document.getElementsByClassName("home-page")[0].scrollTop;
      if (scrollTop > 2000) {
        this.flag_scroll = true;
      } else {
        this.flag_scroll = false;
      }
    },

    backTop() {
      document.getElementsByClassName("home-page")[0].scrollTop = 0;
    },

    // 获取首页优惠券信息
    async getHomeCouponInfo() {
      const res = await getHomeCouponInfo();
      if (res && res.code == 0) {
        this.couponInfo = res.data;
      } else {
        // this.$toast(res.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home-page {
  height: 100%;
  padding: 0rem 0 0.4rem;
  box-sizing: border-box;
  overflow-y: auto;
  // position: relative;
  // overflow-x: hidden;
  // .bgc-box {
  //   background-image: linear-gradient(0deg, #f1503b, #c82519 50%);
  //   position: absolute;
  //   top: -4.8rem;
  //   left: -40%;
  //   height: 7.25rem;
  //   width: 180%;
  //   border-bottom-left-radius: 100%;
  //   border-bottom-right-radius: 100%;
  // }
  .search-div {
    position: fixed;
    z-index: 99999;
    width: 100%;
    background-color: #f6f7f7;
    padding: 0.16rem 0;
  }
  .search-div-content {
    // height: 7.5%;
  }
  .hot-tag-box {
    width: 100%;
    height: 0.44rem;
    overflow: hidden;
    margin-top: 0.16rem;

    .tag-box {
      display: flex;
      flex-wrap: wrap;
    }

    .tag-item {
      height: 0.4rem;
      background: #f1ecec;
      border-radius: 0.2rem;
      padding: 0 0.16rem;
      font-size: 0.22rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.12rem;
      margin-bottom: 0.1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .home-search-box {
    padding: 0 0.2rem;
    background-color: #f6f7f7;
  }

  .main-container {
    padding: 0 0.2rem;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    margin-top: 0.96rem;
  }

  .banner-box {
    height: 2.82rem;
    border-radius: 0.24rem;
    overflow: hidden;

    .van-swipe {
      height: 100%;
      border-radius: 0.24rem;
      overflow: hidden;

      .swiper-img {
        width: 100%;
        height: 100%;
        border-radius: 0.24rem;
      }

      /deep/ .van-swipe-item {
        overflow: hidden;
        border-radius: 0.24rem;
      }

      /deep/ .van-swipe__indicator {
        width: 0.16rem;
        height: 0.08rem;
        background: #ffffff;
        border-radius: 0.06rem;
        opacity: 0.3;
      }
      /deep/ .van-swipe__indicator--active {
        width: 0.24rem;
        opacity: 0.8;
      }
    }
  }

  .promise-box {
    height: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 0.16rem;
    padding: 0 0.24rem;

    .promise-svg {
      height: 0.3rem;
    }
  }

  .menu-box {
    margin-top: 0.2rem;

    /deep/ .van-swipe__track {
      margin-bottom: 0.14rem;
    }

    /deep/ .van-swipe__indicators {
      bottom: 0;
    }

    /deep/ .van-swipe__indicator {
      background-color: #666 !important;
    }

    /deep/ .van-swipe__indicator--active {
      background-color: red !important;
    }

    .menu-grid {
      /deep/ .van-grid-item__content {
        background-color: rgba(0, 0, 0, 0);
        padding: 0.18rem 0.08rem;
      }
      /deep/ .van-grid-item__icon {
        font-size: 0.84rem;
        color: salmon;
      }
      /deep/ .van-grid-item__text {
        margin-top: 0.04rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
      }

      .icon-box {
        width: 0.8rem;
        height: 0.8rem;
        // border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.06rem;
        position: relative;

        .icon {
          width: 100%;
          height: 100%;
        }
      }
      .tag-img {
        width: 0.64rem;
        height: 0.36rem;
        position: absolute;
        right: -0.2rem;
        top: -0.12rem;
      }
    }
  }

  .notice-box {
    height: 0.72rem;
    background: #ffffff;
    border-radius: 0.16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.24rem;
    box-sizing: border-box;
    margin-top: 0.2rem;

    .notice-left {
      display: flex;
      align-items: center;

      .tag-name {
        height: 0.4rem;
        background: #ffa701;
        border-radius: 0.08rem;
        padding: 0 0.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.22rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .notice-text {
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303133;
        margin-left: 0.24rem;

        i {
          font-style: normal;
          color: #ffa701;
          font-weight: 500;
        }
      }
    }

    .notice-right {
      width: 1.12rem;
      height: 0.4rem;
      border-radius: 0.2rem;
      border: 0.01rem solid #ffa701;
      font-size: 0.22rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffa701;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .hot-conver-box {
    margin-top: 0.2rem;
    width: 100%;
    height: 3.34rem;
    background-image: url("../assets/images/hot-bgc.png");
    border-radius: 0.16rem;
    overflow: hidden;
    padding: 0.22rem 0 0.16rem;
    background-repeat: no-repeat;
    background-size: cover;
    .conver-title {
      padding: 0 0.24rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .titles {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .title-left {
          display: flex;
          flex-direction: column;
          .rq-icon {
            width: 1.39rem;
            height: 0.35rem;
          }
          .main-title {
            display: flex;
            font-size: 0.18rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            background-color: #fff;
            border-radius: 1rem;
            color: #ff503f;
            height: 0.32rem;
            line-height: 0.32rem;
            padding: 0 0.1rem;
            align-items: center;
            margin-top: 0.12rem;
            > img {
              width: 0.15rem;
              height: 0.2rem;
            }
          }
        }

        .title {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e63a2e;
          margin-left: 0.16rem;
        }
      }

      .title-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > div {
          width: 1.5rem;
          height: 0.68rem;
          background: #d43730;
          border-radius: 0.16rem;
          font-size: 0.2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff1dd;
        }
        .top-goods {
          background: #fff1dd;
          color: #d43730;
          transform: translateX(0.2rem);
        }

        // .more {
        //   font-size: 0.24rem;
        //   font-family: PingFangSC-Regular, PingFang SC;
        //   font-weight: 400;
        //   color: #999999;
        // }

        // .arrow-ritght {
        //   margin-left: 0.06rem;
        //   color: #999999;
        //   font-size: 0.2rem;
        // }
      }
    }

    .conver-list {
      width: 100%;
      // height: 2.6rem;
      margin-top: 0.16rem;
      padding-left: 0.08rem;

      .item-box {
        // height: 3rem;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .convert-item {
        display: inline-block;
        width: 6.56rem;
        background: #fff;
        border-radius: 0.08rem;
        overflow: hidden;
        margin-left: 0.16rem;
        padding: 0.28rem 0.2rem 0;
        box-sizing: border-box;
        min-height: 2rem;
        &:last-child {
          margin-right: 0.16rem;
        }
        .conver-content {
          display: flex;

          .img-box {
            width: 2.4rem;
            height: 1.44rem;
            border-radius: 0.08rem;
            overflow: hidden;
            // background-color: rgb(139, 44, 44);

            img {
              width: 100%;
              height: 100%;
            }
          }

          .content-box {
            box-sizing: border-box;
            margin-left: 0.24rem;
            display: flex;
            flex-direction: column;
            flex: 1;
            .super-hot-svg {
              width: 0.92rem;
              height: 0.28rem;
              // margin-top: 0.1rem;
            }
          }

          .product-name {
            width: 100%;
            font-size: 0.28rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }

          .integral-box {
            display: flex;
            align-items: flex-start;
            overflow: hidden;
            margin-top: 0.04rem;

            .rq-price {
              width: 100%;
              display: flex;
              justify-content: space-between;
              font-size: 0.26rem;
              color: #303133;
              .price-left {
                display: flex;
                flex-direction: column;
                .price-top {
                  .rmb {
                    font-size: 0.3rem;
                    letter-spacing: -0.02rem;
                  }
                  .now-price {
                    font-size: 0.4rem;
                    // font-family: Roboto-Medium, Roboto;
                    font-weight: 700;
                    color: #fe3201;
                  }
                  .before-coupon {
                    color: #b8b7b7;
                    text-decoration: line-through;
                  }
                }
                .price-bottom {
                  font-size: 0.2rem;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #b8b7b7;
                  display: flex;
                  align-items: center;
                  .down-price {
                    margin-right: 0.04rem;
                    width: 0.18rem;
                    height: 0.1rem;
                  }
                }
              }
              .price-right {
                display: flex;
                height: 0.46rem;
                background: linear-gradient(90deg, #ffb213 0%, #fd3940 100%);
                border-radius: 0.24rem;
                font-size: 0.24rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                align-items: center;
                justify-content: center;
                justify-items: center;
                padding: 0 0.1rem 0 0.16rem;
                align-self: flex-end;
              }
            }
          }
        }
      }
    }
  }

  .fixed-product {
    margin-top: 0.2rem;
    .fixed-top {
      display: flex;
      margin-bottom: 0.2rem;
      .fixed-left {
        width: 50%;
        height: 4rem;
        margin-right: 0.2rem;
        background-color: #fff;
        border-radius: 0.08rem;
        overflow: hidden;
        position: relative;
        justify-content: center;

        &:last-child {
          margin-right: 0;
        }

        &.coupon-center {
          padding: 0.22rem 0.24rem;
          box-sizing: border-box;
          position: relative;

          h3 {
            font-size: 0.32rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 900;
            color: #000000;
          }
          h6 {
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9b9b9b;
            // margin-top: 0.08rem;
          }

          .tag {
            margin-top: 0.14rem;

            span {
              width: 0.98rem;
              height: 0.34rem;
              background: linear-gradient(138deg, #ff9f6c 0%, #ff5e65 100%);
              border-radius: 0.06rem;
              font-size: 0.2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .no-center {
            padding: 0;
            height: 2rem;
            position: absolute;
            bottom: 0;

            .no-coupon {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .action-btn {
            position: absolute;
            right: 0.2rem;
            top: 0.32rem;
            width: 0.96rem;
            height: 0.46rem;
            background: #ff5831;
            border-radius: 0.26rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;

            .arrow {
              font-size: 0.18rem;
            }
          }

          &.bill {
            .action-btn {
              background: #7f3ff6;
            }

            .tag {
              span {
                background: linear-gradient(138deg, #72b0fc 0%, #2083fc 100%);
                // background: #8bbcf8;
              }
            }
          }

          .coupon-img {
            width: 100%;
            height: 1.76rem;
            border-radius: 0.08rem;
            display: block;
            margin-top: 0.12rem;
          }

          .coupon-name {
            font-size: 0.26rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #333333;
            margin-top: 0.12rem;
          }

          .price-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin-top: 0.08rem;

            .price-left {
              font-size: 0.2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ff503f;

              .new-price {
                font-size: 0.36rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ff503f;
              }
              .old-price {
                font-size: 0.2rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #d1d1d1;
                text-decoration: line-through;
              }
            }

            .price-right {
              width: 1rem;
              height: 0.28rem;
              border-radius: 0.04rem;
              border: 0.01rem solid #ff503f;
              font-size: 0.2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ff503f;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

    .fixed-right {
      display: flex;
      justify-content: space-between;
      .product {
        width: 50%;
        height: 1.72rem;
        margin-right: 0.2rem;
        background-color: #fff;
        border-radius: 0.08rem;
        overflow: hidden;

        &:last-child {
          margin-right: 0;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .product-one {
        margin-bottom: 0.12rem;
      }
    }
  }

  .product-box {
    margin-top: 0.2rem;

    // .product-tab {
    //   height: 1.02rem;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;

    //   .nav-box {
    //     width: 3.24rem;
    //     height: 1.02rem;
    //     flex-direction: column;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     .main-title {
    //       font-size: 0.28rem;
    //       font-family: PingFangSC-Medium, PingFang SC;
    //       font-weight: 500;
    //       color: #303133;
    //     }
    //     .sub-title {
    //       height: 0.28rem;
    //       border-radius: 0.15rem;
    //       padding: 0 0.12rem;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       font-size: 0.2rem;
    //       font-family: PingFangSC-Regular, PingFang SC;
    //       font-weight: 400;
    //       color: #909399;
    //       margin-top: 0.06rem;
    //     }

    //     &.active {
    //       .main-title {
    //         color: #ff4c31;
    //       }
    //       .sub-title {
    //         color: #fff;
    //         background: #ff4c31;
    //       }
    //     }
    //   }

    //   .spancee-line {
    //     width: 0.02rem;
    //     height: 0.4rem;
    //     background: #e8ebeb;
    //   }
    // }

    .you-like-box {
      width: 100%;
      height: 0.4rem;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-top: 0.4rem;

      .like-icon {
        width: 0.32rem;
        height: 0.32rem;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .like-title {
        font-size: 0.32rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 0.16rem;
      }
    }
  }

  .product-list {
    // padding-top: 0.2rem;
  }

  /deep/.van-overlay {
    z-index: 999999;
  }

  .backTop {
    position: fixed;
    right: 20px;
    bottom: 60px;
  }

  /deep/.van-button {
    height: 37px;
  }
  /deep/.van-button--normal {
    padding: 0 10px;
  }
}
</style>
