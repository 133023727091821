<template>
  <div class="digital-con">
    <div
      class="product-item-digital"
      v-for="(item, index) in data"
      :key="index"
      @click="clickGoods(item)"
    >
      <div class="product-item-digital-box">
        <div class="digital-img-box">
          <img :src="item.productImg" alt="" />
        </div>
        <div class="digital-content">
          <div class="goods-name">
            <span v-if="item.isTag" class="goods-tag">立减</span
            >{{ item.productName }}
          </div>
          <div class="goods-price">
            <div class="price-left">
              <span class="unit">￥</span>
              <span class="price">{{
                mathManage.formatMoney(item.productPrice, 2)
              }}</span>
            </div>
            <span class="product-type">{{
              productType[item.deliveryMode]
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mathManage from "@/utils/mathManage";
import { productType } from "@/utils/dictionary";
export default {
  name: "WaterList",
  props: {
    data: {
      default: () => [],
    },
  },
  data() {
    return {
      productType,
      mathManage,
    };
  },
  methods: {
    // 点击商品
    clickGoods(item) {
      this.$router.push(`/mall/ProductDetails?groupId${item.id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.digital-con {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  column-count: 2;
  column-gap: 0.22rem;

  .product-item-digital {
    // float: left;
    // width: 49%;
    // min-height: 3.52rem;

    // margin-bottom: 0.24rem;
    break-inside: avoid;
    padding-bottom: 0.24rem;

    .product-item-digital-box {
      border-radius: 0.12rem;
      overflow: hidden;
    }

    .digital-img-box {
      height: 1.94rem;
      overflow: hidden;
      // background-color: cornflowerblue;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .digital-content {
      padding: 0.18rem 0.16rem;
      // min-height: 1.58rem;
      overflow: hidden;
      background-color: #fff;

      .goods-name {
        max-height: 0.78rem;
        font-size: 0.28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        white-space: pre-wrap;
        display: -webkit-box;
        /* 弹性盒旧式语法 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        /* 限制两行 */
        overflow: hidden;

        .goods-tag {
          display: inline-block;
          height: 0.32rem;
          background: linear-gradient(117deg, #ff7d58 0%, #ff503f 100%);
          border-radius: 0.04rem;
          padding: 0 0.06rem;
          font-size: 0.2rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          line-height: 0.34rem;
          margin-right: 0.08rem;
          vertical-align: middle;
        }
      }
      .goods-price {
        margin-top: 0.2rem;
        height: 0.36rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price-left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .unit {
          font-size: 0.24rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #ff503f;
        }
        .price {
          font-size: 0.36rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff503f;
        }

        .product-type {
          font-size: 0.18rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          background: #f9f9f9;
          padding: 0 0.04rem;
        }

        .tip {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: normal;
          color: #ff503f;
          margin-left: 0.04;
          padding-top: 0.02rem;
          margin-right: 0.3rem;
        }

        .del {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b1b1b1;
        }
      }

      .face-value {
        font-size: 0.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #b1b1b1;
        display: flex;
        align-items: center;
        margin-top: 0.12rem;
      }
    }
  }
}
</style>
